import { Label, P } from '@student-coin/coinpaper-components';
import useTranslation from 'next-translate/useTranslation';
import { ReactElement } from 'react';
import { ContainLink } from '../ContainLink/ContainLink';
import { Content, Footer, Header, Thumbnail } from '../shared';
import { Thumbnail as ThumbnailType } from '../shared/Thumbnail/Thumbnail.types';
import { ContainDesc, Container, StyledH2 } from './CollectionCard.styles';
import { CollectionCardProps } from './CollectionCard.types';

export const CollectionCard = ({
  collection,
  href,
  size = 'mediumXx',
  variant = 'small',
  thumbnail = 'top',
}: CollectionCardProps): ReactElement => {
  const { t } = useTranslation('common');
  const collectionsPieces = t('collections-pieces', {
    collectionPieces: collection.articles.length,
  });

  return (
    <Container variant={variant} thumbnail={thumbnail} className="collection-card">
      <Thumbnail
        variant={variant}
        position={thumbnail}
        thumbnail={collection.thumbnail as ThumbnailType}
        size={size}
      />

      <Content>
        <ContainLink href={href} collection>
          <ContainDesc>
            <Header>
              <Label>{collectionsPieces}</Label>
            </Header>
            <StyledH2 as="h3">{collection.name}</StyledH2>
            <P variant="small">{collection.homepageDescription}</P>
          </ContainDesc>
          <Footer
            author={collection.author}
            updatedAt={collection.updatedAt}
            publishedAt={collection.publishedAt}
            readTimeInMinutes={collection.articles.reduce(
              (previous, current) => previous + (current.readTimeInMinutes ?? 2),
              0,
            )}
            collection
            readTimeShow={false}
          />
        </ContainLink>
      </Content>
    </Container>
  );
};
