import { H2 } from '@student-coin/coinpaper-components';
import styled from 'styled-components';
import { Container as ContainerBase } from '../shared/Container/Container.styles';
import { Content } from '../shared/Content/Content.styles';

export const Container = styled(ContainerBase)`
  position: relative;
  ${Content} > P {
    line-height: 1.375rem;
  }

  ${H2} {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
`;

export const ContainDesc = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledH2 = styled(H2)`
  position: relative;
  z-index: 3;
  &:hover {
    text-decoration: underline;
  }
`;
